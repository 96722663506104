
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class PackageComparingResult extends Vue {
  /**
   * 是否显示
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private value: boolean;

  /**
   * 全项对比
   * @private
   * @returns string
   */
  @Prop({ type: Array })
  private wholeComparing: any;

  /**
   * 差异项对比
   * @private
   * @returns string
   */
  @Prop({ type: Array })
  private differenceComparing: any;

  /**
   * 是否显示
   * @private
   * @returns boolean
   */
  private isShow: boolean = true;

  /**
   * 组件创建钩子
   * @private
   * @returns string
   */
  private created() {}

  /**
   * 是否显示对比弹框
   * @private
   * @returns boolean
   */
  private get showPopup(): boolean {
    return this.value;
  }

  /**
   * 返回弹框状态
   * @private
   */
  private set showPopup(value: boolean) {
    this.$emit("input", value);
  }

  /**
   * 关闭
   * @private
   */
  private onCancel(bol) {
    this.$emit("close", bol);
  }

  /**
   * 数据
   * @private
   */
  private state: any = {
    active: 0,
    uuid: "",
    supplierId: null,
    dataSource: [],
  };

  /**
   * 返回套餐对比
   * @private
   */
  private onBackPackage() {}
}
